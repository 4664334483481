import { FC, PropsWithChildren, useState } from "react";
import { Button, Card, ExternalLink, Image, Text, WalletConnectDialog, TARGET_BLANK } from "@sundae/ui-toolkit";
import { useWalletActions } from "@sundae/wallet-react";
import type { KnownCip30WalletId } from "@sundae/wallets";
import cx from "classnames";

import { useAppState } from "../../state/context";
import Footer from "../Footer/Footer";
import { ErrorBoundary } from "react-error-boundary";

export enum EEXTERNAL_PATHS {
  DISCORD = "https://discord.gg/Sundae",
  LEGAL = "https://www.sundaeswap.finance/terms",
  LEARN_MORE_WALLETS = "https://help.sundaeswap.finance/en/collections/3303291-wallets",
  DISCLAIMER = "https://sundaeswap.finance/terms",
  PRIVACY = "https://sundaeswap.finance/privacy",
  TERMS = "https://sundaeswap.finance/terms",
  TWITTER = "https://twitter.com/SundaeSwap",
  MEDIUM = "https://sundaeswap-finance.medium.com/",
  FAQ = "https://help.sundaeswap.finance/en/",
}

const Layout: FC<PropsWithChildren<{ title?: string }>> = ({
  title = "Claim your Sundae Galaxy Coin!",
  children,
}) => {
  const {
    connectWallet,
    loading: { isConnectingWallet },
  } = useWalletActions();
  const { isConnectDialogOpen, setConnectDialogOpen } = useAppState();
  const [error, setError] = useState("");

  const handleConnect = async (key: KnownCip30WalletId) => {
    try {
      await connectWallet(key);
      setConnectDialogOpen(false);
    } catch (err) {
      const error = err as Error;
      if (error?.message && error?.message === "no account set") {
        setError("Please open your wallet and select an account you'd like to connect with.");
      }
    }
  };

  return (
    <div
      className={cx(
        "container relative mx-auto flex min-h-screen w-full items-center justify-end px-4 dark:bg-gray-900 lg:px-12"
      )}
    >
      <div className="fixed left-0 z-0 hidden h-full w-full overflow-hidden lg:block">
        <Image
          src="/static/images/bg-main.png"
          className=" w-[1400px] -translate-y-48 translate-x-20"
        />
      </div>
      <div
        className={cx(
          "relative ml-auto flex w-full flex-col items-end justify-start",
          "lg:max-w-3xl"
        )}
      >
        <Text tag="h2" weight="bold" size="7xl" className="mb-8 w-full text-left">
          {title}
        </Text>
        <Card className="w-full !border-purple shadow-2xl !shadow-gray-900/20 dark:!shadow-gray-900 lg:p-8">
          <ErrorBoundary
            fallbackRender={({ resetErrorBoundary, error }) => (
              <div className="flex flex-col items-center justify-between">
                <Text
                  tag="h3"
                  weight="bold"
                  size="4xl"
                  className="flex items-center justify-start gap-4"
                >
                  Whoops! Something went wrong.{" "}
                </Text>
                <pre className="mt-2">
                  <strong>Error:</strong> {error.message}
                </pre>
                <Button variant="secondary" onClick={resetErrorBoundary} className="mt-8">
                  Try again.
                </Button>
              </div>
            )}
          >
            {children}
          </ErrorBoundary>
        </Card>
        <Footer />
      </div>
      <WalletConnectDialog
        onClose={() => setConnectDialogOpen(false)}
        open={isConnectDialogOpen}
        onConnectWallet={handleConnect}
        isLoading={isConnectingWallet}
        learnMoreRoute={EEXTERNAL_PATHS.LEARN_MORE_WALLETS}
        i18n={{
          error,
          description: "New to Cardano?",
          learnMore: "Learn more about Wallets",
          noWallets:
            "Seems like you have no Wallet installed. Please make sure to download and create at least one Wallet in order to interact with this page.",
          title: "Connect a Wallet",
        }}
        legalDisclaimer={
          <Text className="flex-1 flex-wrap" tag="p" size="xs">
            By connecting your wallet, you agree to our
            <ExternalLink
              {...TARGET_BLANK}
              href={EEXTERNAL_PATHS.DISCLAIMER}
              onClick={(e) => e.stopPropagation()}
              size="xs"
              variant="primary"
            >
              Disclaimer
            </ExternalLink>
            ,
            <ExternalLink
              {...TARGET_BLANK}
              href={EEXTERNAL_PATHS.TERMS}
              onClick={(e) => e.stopPropagation()}
              size="xs"
              variant="primary"
            >
              Use Terms
            </ExternalLink>
            and our
            <ExternalLink
              {...TARGET_BLANK}
              href={EEXTERNAL_PATHS.PRIVACY}
              onClick={(e) => e.stopPropagation()}
              size="xs"
              variant="primary"
            >
              Privacy Policy
            </ExternalLink>
            .
          </Text>
        }
        noWalletsComponent={<p>You have no wallets!</p>}
      />
    </div>
  );
};

export default Layout;
