import { useTheme } from "@sundae/react-hooks";
import { Button, ExitIcon, ExternalLink, MoonIcon, SunIcon, Text } from "@sundae/ui-toolkit";
import { useWallet, useWalletActions } from "@sundae/wallet-react";
import { FC } from "react";

import { EEXTERNAL_PATHS } from "../Layout/Layout";

const Footer: FC = () => {
  const {
    state: {
      initialized: { wallet: walletLoaded },
      wallet,
    },
    loading: { isCheckingWallet },
  } = useWallet();
  const { logoutWallet } = useWalletActions();
  const { theme, toggleTheme } = useTheme();
  return (
    <div className="mt-8 flex w-full items-center justify-end gap-4">
      {walletLoaded && (
        <Text tag="p" size="sm" variant="muted" className="mr-auto">
          Wallet Status: {isCheckingWallet ? "Updating..." : "Synced!"}
        </Text>
      )}
      <ExternalLink href={EEXTERNAL_PATHS.LEGAL}>Legal</ExternalLink>
      <Button variant="secondary" onClick={toggleTheme} className="!min-w-max">
        {theme === "dark" ? <SunIcon /> : <MoonIcon />}
      </Button>
      {walletLoaded && wallet && (
        <Button variant="secondary" onClick={logoutWallet}>
          Disconnect
          <ExitIcon />
        </Button>
      )}
    </div>
  );
};

export default Footer;
