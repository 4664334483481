import { createContext, FC, PropsWithChildren, useContext, useState } from "react";
import { IAppState } from "./types";

const defaultState: IAppState = {
  isConnectDialogOpen: false,
  setConnectDialogOpen: () => {},
};

const AppContext = createContext(defaultState);
export const useAppState = () => useContext(AppContext);
export const AppContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState();

  return (
    <AppContext.Provider
      value={{
        isConnectDialogOpen: dialogOpen,
        setConnectDialogOpen: setDialogOpen,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
