import { Button, Loader, SundaeSwapLogo, Text, toolkitCx } from "@sundae/ui-toolkit";
import { useMatch } from "@tanstack/react-location";
import { FC, Suspense, lazy, useMemo } from "react";
import { Buffer } from "buffer";
import { ErrorBoundary } from "react-error-boundary";

const LazyPreviewCoin = lazy(() => import("../../components/PreviewCoin"));
import Layout from "../../components/Layout";

const Preview: FC = () => {
  const {
    data: { id },
  } = useMatch();
  const decodedFreezerGroup = Buffer.from(id as string, "base64").toString("utf-8");
  const freezerGroup = useMemo(() => decodedFreezerGroup.split("-")[0], [decodedFreezerGroup]);
  const length = useMemo(() => decodedFreezerGroup.split("-")[1], [decodedFreezerGroup]);

  return (
    <>
      <Layout title="Preview Your Chip">
        <Suspense
          fallback={
            <div className="flex h-full w-full flex-col items-center justify-center gap-8 pb-8">
              <SundaeSwapLogo width={60} height={60} className="m-8 mb-0" />
              <div className={toolkitCx.recipes.center.both()}>
                <Loader size="medium" />
              </div>
            </div>
          }
        >
          <ErrorBoundary
            fallbackRender={({ resetErrorBoundary }) => (
              <div className="flex flex-col items-center justify-between">
                <Text
                  tag="h3"
                  weight="bold"
                  size="4xl"
                  className="flex items-center justify-start gap-4"
                >
                  Whoops! Something went wrong.{" "}
                </Text>
                <Button variant="secondary" onClick={resetErrorBoundary} className="mt-8">
                  Try again.
                </Button>
              </div>
            )}
          >
            <LazyPreviewCoin freezerGroup={freezerGroup} length={Number(length)} />
          </ErrorBoundary>
        </Suspense>
      </Layout>
    </>
  );
};

export default Preview;
