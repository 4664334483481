import { FC } from "react";
import WalletProvider from "@sundae/wallet-react";
import { createHashHistory, Outlet, ReactLocation, Route, Router } from "@tanstack/react-location";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { AppContextProvider } from "./state/context";

import "./styles";
import Home from "./pages/Home";
import Preview from "./pages/Preview";

const AppRoutes: Route[] = [
  {
    id: "home",
    path: "/",
    element: <Home />,
  },
  {
    id: "preview",
    path: "/preview/:id",
    element: <Preview />,
    loader: ({ params }) => {
      return {
        id: params.id,
      };
    },
  },
];

const history = createHashHistory();
const location = new ReactLocation({ history });

const client = new QueryClient();

const App: FC = () => {
  return (
    <Router useErrorBoundary location={location} routes={AppRoutes}>
      <QueryClientProvider client={client}>
        <AppContextProvider>
          <WalletProvider options={{ useHandles: true }}>
            <Outlet />
          </WalletProvider>
        </AppContextProvider>
      </QueryClientProvider>
    </Router>
  );
};

export default App;
