import { createRoot } from "react-dom/client";
import WebFont from "webfontloader";

import App from "./App";

const target = document.querySelector("#app");
if (target) {
  WebFont.load({
    google: {
      families: ["DM Mono"],
    },
  });
  const root = createRoot(target);
  root.render(<App />);
}
