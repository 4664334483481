import { Loader, SundaeSwapLogo, toolkitCx } from "@sundae/ui-toolkit";
import { FC, Suspense, lazy } from "react";

import Layout from "../../components/Layout";
const LazyWalletFreezers = lazy(() => import("../../components/WalletFreezers"));

const Home: FC = () => {
  return (
    <>
      <Layout>
        <Suspense
          fallback={
            <div className="flex h-full w-full flex-col items-center justify-center gap-8 pb-8">
              <SundaeSwapLogo width={60} height={60} className="m-8 mb-0" />
              <div className={toolkitCx.recipes.center.both()}>
                <Loader size="medium" />
              </div>
            </div>
          }
        >
          <LazyWalletFreezers />
        </Suspense>
      </Layout>
    </>
  );
};

export default Home;
